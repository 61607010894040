import React from 'react';
import {
  symbols___infoCircle,
  interaction_arrows_arrowRight,
  Typography,
} from '@neui/styleguide-commerzbank';
import { VStack } from '@neui/layout';

import { NewsDataType } from 'pages';
import { NewsHtmlParser } from '@components/HtmlParser/NewsHtmlParser';
import { BannerCollapsible } from '@components/neui-components/molecules/BannerCollapsible';
import { IconLink } from '@components/neui-components/atoms/IconLink';

type NewsBannerProps = {
  news: NewsDataType;
};

export function NewsBanner({ news }: NewsBannerProps) {
  return (
    <BannerCollapsible
      icon={symbols___infoCircle}
      size={'large'}
      variant="outline"
      headline={
        <VStack>
          <Typography weight={'medium'} size={5}>
            {news.title}
          </Typography>
          <Typography weight={'book'} size={7}>
            {news.subline}
          </Typography>
        </VStack>
      }
      actions={
        news.url && (
          <IconLink
            icon={interaction_arrows_arrowRight}
            href={news.url}
            animationDirection="top"
          >
            Mehr erfahren
          </IconLink>
        )
      }
    >
      <VStack spacing="$component-4">
        <NewsHtmlParser basename="cds-wissen" content={news.content} />
      </VStack>
    </BannerCollapsible>
  );
}
