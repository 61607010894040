/* eslint-disable @next/next/no-img-element */
import { Box, SimpleGrid, VStack } from '@neui/layout';
import {
  Typography,
  Headline as NeuiHeadline,
  Text,
  IconThumbnail,
  interaction_arrows_arrowRight,
  Headline,
} from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';

import { Section } from '@components/neui-components/atoms/Section';
import { useTracker } from '@utils/snowplowTracking';
import { cdsIconGroupInfo } from '@utils/iconGroupInfo';
import { categoryMap } from '@utils/categoryInfo';
import { IconLink, Wrapper } from '@components/neui-components/atoms/IconLink';
import { TeaserCard } from '@components/neui-components/molecules/TeaserCard';
import { ActionButton } from '@components/neui-components/molecules/ActionButton';
import { NewsBanner } from '@components/NewsBanner/NewsBanner';
import { $t } from '@utils/i18n';
import { makeStagedLinkFunction } from '@utils/helpers/linkHelpers';
import { useMakeLink } from '@components/Link';
import { ChangeServicePortal } from '@components/ChangeServicePortal';
import {
  GA4TrackLinkClick,
  GA4TrackNavigationClick,
  GA4TrackTeaserCtaClick,
} from '@utils/tracking';
import { MostReadArticles } from '@components/MostReadArticles/MostReadArticles';
import { ClickItem } from '@components/neui-components/atoms/ClickItem';
import { Image } from '@components/Image';

import { CdsIndexPageProps } from '../pages/index';

export const CdsIndexPage = ({
  topCategories,
  metadata,
  mostReadArticles,
  newsData,
}: CdsIndexPageProps) => {
  const { trackButtonClick, trackLinkClick } = useTracker(CdsIndexPage.name);
  const { basename, deploymentStage } = metadata;
  const makeStagedLink = makeStagedLinkFunction(deploymentStage);
  const makeLink = useMakeLink();

  const appointmentUrl =
    'https://www.commerzbank.de/kontakt/terminvereinbarung-split/';
  const appointmentText = 'Termin vereinbaren';

  const handleAppointmentClick = () => {
    GA4TrackLinkClick(appointmentUrl, appointmentText, undefined, 'Homepage');
    trackLinkClick?.(appointmentUrl, appointmentText);
  };

  return (
    <>
      <Section>
        <Headline type={'h3'} renderAs="h1">
          {$t('SEARCH_HEADLINE')}
        </Headline>
        <VStack spacing={'$subsection'}>
          {newsData && newsData.length > 0 && <NewsBanner news={newsData[0]} />}
          <Text type={'helper'}>{$t('SEARCH_SUBLINE')}</Text>
          <StyledUl>
            {cdsIconGroupInfo(makeStagedLink).map((topic, index) => (
              <StyledLi key={index}>
                <ActionButton
                  icon={topic.icon}
                  look={'secondary'}
                  href={topic.url}
                  label={topic.label}
                  onClick={() => {
                    trackButtonClick?.(topic.label, 'self_service', []);
                    GA4TrackNavigationClick(
                      'navigationClick',
                      'ActionButton',
                      topic.label,
                      makeLink({ href: topic.url, absoluteUrl: true }),
                    );
                  }}
                />
              </StyledLi>
            ))}
          </StyledUl>
        </VStack>
      </Section>
      <MostReadArticles
        trackButtonClick={trackButtonClick}
        mostReadArticles={mostReadArticles}
      />
      <Section>
        <VStack spacing={'$subsection'}>
          <NeuiHeadline
            type="h3"
            renderAs="h2"
            subline={
              'Hier finden Sie Informationen zu allen Themen rund um Produkte, Verwaltung, Online Banking und weitere Bankthemen.'
            }
          >
            Unterstützung & Informationen
          </NeuiHeadline>
          <SimpleGrid
            spacing={{ base: '$space$component-8' }}
            columns={{ base: 1, xs: 1, sm: 2, md: 3 }}
          >
            {topCategories.map((category, index) => {
              const { slug, title } = category;
              const categoryInfo = categoryMap[basename].get(slug);

              if (categoryInfo === undefined) {
                throw new Error(
                  'Sabio categories are not matching the configuration in utils/categoryInfo.ts',
                );
              }

              const onClick = () => {
                GA4TrackTeaserCtaClick(
                  'CTA Picture',
                  title,
                  'Mehr erfahren',
                  makeLink({
                    href: slug,
                    absoluteUrl: true,
                    alwaysPrependBasePath: true,
                  }),
                  'Unterstützung & Informationen',
                );
                trackButtonClick?.(title, 'category', []);
              };
              return (
                <TeaserCard
                  key={index}
                  href={slug}
                  onClick={onClick}
                  hover
                  bordered
                  picture={
                    <ImageWrapper>
                      <Image
                        src={categoryInfo.icon as string}
                        alt=""
                        loading="lazy"
                      />
                    </ImageWrapper>
                  }
                  footer={
                    <StyledIconLink
                      iconPosition="right"
                      animationDirection="none"
                      renderAs={Typography}
                      icon={interaction_arrows_arrowRight}
                      data-cy={'category'}
                    >
                      Mehr erfahren
                    </StyledIconLink>
                  }
                >
                  <VStack spacing={{ base: 8, md: 16 }}>
                    <Typography
                      size={4}
                      weight={'medium'}
                      aria-level={3}
                      role="heading"
                    >
                      {title}
                    </Typography>
                    <Text textAlign={'left'} type="info">
                      {categoryInfo.description}
                    </Text>
                  </VStack>
                </TeaserCard>
              );
            })}
          </SimpleGrid>
        </VStack>
      </Section>
      <ChangeServicePortal />
      <StyledSection>
        <StyledClickItem
          iconText={appointmentText}
          href={appointmentUrl}
          onClick={handleAppointmentClick}
        >
          <VStack
            flexDirection={'row'}
            alignItems={'center'}
            spacing={'$component-4'}
          >
            <StyledIconThumbnail icon={{ icon: 'object___calender' }} />
            <VStack>
              <Typography
                weight={'medium'}
                size={5}
                role="heading"
                aria-level={3}
              >
                Sie haben ein individuelles Anliegen?
              </Typography>
              <Typography size={7} color={'helper'}>
                Vereinbaren Sie einen Termin für Ihre persönliche Beratung per
                Telefon, Video oder in Ihrer Filiale.
              </Typography>
            </VStack>
          </VStack>
        </StyledClickItem>
      </StyledSection>
    </>
  );
};

// TODO: fix sand theme tokens on cblsg package
export const SandSection = styled(Section, {
  background: '$colors$petrol0 !important',
});

export const ImageWrapper = styled(Box, {
  position: 'relative',
  width: '100%',
  maxWidth: '375px',
  margin: '0 auto',
  height: 'auto',
  '& img, & picture, & video, & svg': {
    inset: 0,
    width: '100%',
    height: '100%',
  },
});

const StyledClickItem = styled(ClickItem, {
  maxWidth: 792,
});

const StyledIconThumbnail = styled(IconThumbnail, {
  minWidth: '50px !important',
});

const StyledSection = styled(Section, {
  paddingTop: '0 !important',
});

export const StyledUl = styled('ul', {
  gridGap: '$layout-2',
  display: 'flex',
  flexWrap: 'wrap',
  '@lg': {
    gridGap: '$layout-3 $layout-2',
  },
});

export const StyledLi = styled('li', {
  listStyle: 'none',
  width: '112px',
  '@lg': {
    gap: '$layout-5',
    width: '128px',
  },
});

export const StyledIconLink = styled(IconLink, {
  '&:focus, &:focus-visible': {
    [`& ${Wrapper}`]: {
      outline: 'none',
    },
  },
});
