/* eslint-disable @next/next/no-img-element */
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SimpleGrid, VStack } from '@neui/layout';
import {
  Typography,
  Headline as NeuiHeadline,
  Text,
  sandTheme,
  interaction_arrows_arrowRight,
  Headline,
} from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';
import React, { useRef, useState } from 'react';

import { Image } from '@components/Image';
import { Section } from '@components/neui-components/atoms/Section';
import { TeaserCard } from '@components/neui-components/molecules/TeaserCard';
import { ActionButton } from '@components/neui-components/molecules/ActionButton';
import { $t } from '@utils/i18n';
import { makeStagedLinkFunction } from '@utils/helpers/linkHelpers';
import { useMakeLink } from '@components/Link';
import { createPortalEntity, useTracker } from '@utils/snowplowTracking';
import { iconGroupInfo } from '@utils/OB-iconGroupInfo';
import { categoryMap, CategoryInfo } from '@utils/categoryInfo';
import { Layer } from '@components/neui-components/molecules/Layer';
import {
  Accordion,
  AccordionComponentItemProps,
} from '@components/neui-components/molecules/Accordion';
import {
  OnlineBankingServicesProps,
  getOnlineBankingServices,
} from '@utils/OB-services';
import { ChatEntryPoint } from '@components/Chat/ChatEntryPoint';
import { selectChatProps, useChatStore } from '@components/Chat/store/store';
import {
  GA4SearchType,
  GA4TrackAccordionOpen,
  GA4TrackLinkClick,
  GA4TrackNavigationClick,
  GA4TrackSideLayerOpen,
  GA4TrackTeaserCtaClick,
} from '@utils/tracking';
import { ClickItem } from '@components/neui-components/atoms/ClickItem';
import {
  getSearchParameters,
  getUrlParameter,
} from '@components/Search/helpers';
import { CdsSearch } from '@components/Search/CdsSearch';
import { SuggestionResultType } from '@components/Search';

import { CdsIndexPageProps } from '../pages/index';
import { XColumnsGridItem } from './XColumnsGridItem';
import {
  ImageWrapper,
  SandSection,
  StyledIconLink,
  StyledLi,
  StyledUl,
} from './CdsIndexPage';

export const OBIndexPage = ({
  topCategories,
  metadata,
  mostSearchedTerms,
}: CdsIndexPageProps) => {
  const { trackButtonClick } = useTracker(OBIndexPage.name);
  const portalContext = createPortalEntity('lp', OBIndexPage.name);

  const { basename, deploymentStage } = metadata;
  const router = useRouter();

  const makeStagedLink = makeStagedLinkFunction(deploymentStage);
  const makeLink = useMakeLink();

  const [, setSuggestions] = useState<SuggestionResultType[]>([]);

  const { trackLinkClick } = useTracker(ChatEntryPoint.name);

  const [isOpen, openLayer] = React.useState(false);
  const onlineBankingServices = getOnlineBankingServices(makeStagedLink);

  const [serviceTitle, setServiceTitle] = React.useState(
    onlineBankingServices[0].title,
  );
  const { chatState } = useChatStore(selectChatProps);
  const [idx, setIdx] = React.useState(0);
  const getSubcategory = (index: number) =>
    onlineBankingServices[idx].categories[index].subcategories.map(
      (subcategory) => (
        <>
          <VStack spacing={'$component-5'}>
            <Typography>{subcategory.subcategoryTitle}</Typography>

            <VStack
              alignItems={'flex-start'}
              css={{ marginBottom: '$component-6', padding: '0 2px' }}
            >
              {subcategory.links.map((link, i) => (
                <ClickItem
                  key={i}
                  href={link.href}
                  onClick={() => {
                    trackLinkClick?.(link.href, link.linkTitle);
                    GA4TrackLinkClick(
                      link.href,
                      link.linkTitle,
                      onlineBankingServices[idx].title,
                      'Sidelayer',
                      onlineBankingServices[idx].categories[index]
                        .categoryTitle,
                    );
                  }}
                >
                  <Typography weight={'book'}>{link.linkTitle}</Typography>
                </ClickItem>
              ))}
            </VStack>
          </VStack>
        </>
      ),
    );

  const handleAccordionValueChange = (value: string | null) => {
    if (value && value?.length > 1) {
      GA4TrackAccordionOpen(serviceTitle, value);
      trackButtonClick?.(value, 'ob_services', [portalContext]);
    }
  };

  function transformItems(
    items: OnlineBankingServicesProps,
  ): AccordionComponentItemProps[] {
    return items.categories.map((item, categoryTitleIndex) => ({
      label: item.categoryTitle,
      children: getSubcategory(categoryTitleIndex),
      titleAs: 'h3',
    }));
  }

  const resetQuery = useRef<(query: string) => void>();

  const [, setIsSearchFocused] = useState(false);

  const { searchUrl } = metadata;

  const itm = router.pathname.includes('/search')
    ? getUrlParameter(router, 'itm')
    : undefined;

  const portals = [
    {
      regex: /nachhaltigkeit/,
      url: 'https://www.commerzbank.de/nachhaltigkeit',
    },
    {
      regex: /sustainability/,
      url: 'https://www.commerzbank.de/sustainability',
    },
    { regex: /konzern/, url: 'https://www.commerzbank.de/konzern/' },
    { regex: /group/, url: 'https://www.commerzbank.de/group/' },
  ];

  let nachKonSearchUrl = process.env.NEXT_PUBLIC_NACHKON_SEARCH_URL_DE ?? ''; //DE default

  const isNachKon: boolean =
    itm !== undefined && portals.some((portal) => portal.regex.test(itm));

  const isNachKonDE: boolean =
    itm !== undefined &&
    (portals[0].regex.test(itm) || portals[2].regex.test(itm));

  const searchApiUrl = !isNachKon
    ? searchUrl
    : isNachKonDE
      ? nachKonSearchUrl
      : process.env.NEXT_PUBLIC_NACHKON_SEARCH_URL_EN ?? '';

  const searchParameters = getSearchParameters(router);

  const executeSearch = async (query: string, searchType: GA4SearchType) => {
    window.location.href = `${router.basePath}/?q=${query}&searchType=${searchType}`;
  };
  return (
    <>
      <Head>
        <meta name="de-comdirect-provider-template" content="layout_5_0" />
      </Head>
      <Section css={{ '& > div': { gap: '$layout-2' } }}>
        <Headline type={'h3'} renderAs="h1">
          {$t('SEARCH_HEADLINE')}
        </Headline>
        <VStack spacing={'$subsection'}>
          <SearchContainer>
            <CdsSearch
              searchUrl={searchApiUrl}
              initialQuery={searchParameters.q}
              executeSearch={executeSearch}
              onFocusChange={setIsSearchFocused}
              onSuggestionsChange={setSuggestions}
              setResetQuery={(f) => (resetQuery.current = f)}
              isDE={true}
              variant="base"
              compactLayout={true}
              mostSearchedTerms={mostSearchedTerms}
            />
          </SearchContainer>
          <Text type={'helper'}>{$t('SEARCH_SUBLINE')}</Text>
          <StyledUl>
            {iconGroupInfo(makeStagedLink).map((topic, index) => (
              <StyledLi key={index}>
                <ActionButton
                  icon={topic.icon}
                  look={'secondary'}
                  href={topic.url}
                  label={topic.label}
                  onClick={() => {
                    trackButtonClick?.(topic.label, 'self_service', []);
                    GA4TrackNavigationClick(
                      'navigationClick',
                      'OB ActionButton',
                      topic.label,
                      makeLink({ href: topic.url, absoluteUrl: true }),
                    );
                  }}
                />
              </StyledLi>
            ))}
          </StyledUl>
        </VStack>
      </Section>
      {chatState.workgroupName && (
        <StyledSection>
          <XColumnsGridItem columns={{ base: 12, lg: 8 }}>
            <ChatEntryPoint
              headline={'Wir sind für Sie da'}
              text={
                'Persönlich und rund um die Uhr. Im authentifizierten Kundencenter können wir nicht nur Ihre Fragen beantworten, sondern auch viele weitere Leistungen anbieten. Vom Girokonto bis zur neuen Kreditkarte.'
              }
              portalContext={portalContext}
            />
          </XColumnsGridItem>
        </StyledSection>
      )}
      <SandSection theme={'sand'} className={sandTheme}>
        <NeuiHeadline
          type="h3"
          renderAs="h2"
          textAlign={'left'}
          css={{ alignSelf: 'flex-start' }}
        >
          Services im Online Banking
        </NeuiHeadline>
        <SimpleGrid
          spacing={{ base: '$space$component-8' }}
          columns={{ base: 1, xs: 1, sm: 2, md: 3 }}
        >
          {onlineBankingServices.map(
            (service: OnlineBankingServicesProps, index) => {
              const onClick = (
                e: React.MouseEvent<HTMLElement, MouseEvent>,
              ) => {
                e.preventDefault();
                //   trackButtonClick?.(service.slug, 'top_article', []);
                //   navigate(service.slug, router);
                GA4TrackSideLayerOpen(service.title);
                openLayer(true);
                setServiceTitle(service.title);
                setIdx(index);
              };

              const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  e.preventDefault();
                  openLayer(true);
                  GA4TrackSideLayerOpen(service.title);
                  setServiceTitle(service.title);
                  setIdx(index);
                }
              };

              return (
                <>
                  <StyledTeaserCard
                    key={index}
                    // href={dynamicHref(service.slug)}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    tabIndex={0}
                    hover
                    // linkLabel="Zu den Direktlinks"
                    footer={
                      <StyledIconLink
                        iconPosition="right"
                        animationDirection="none"
                        renderAs={Typography}
                        icon={interaction_arrows_arrowRight}
                        data-cy={'top-article'}
                      >
                        Zu den Direktlinks
                      </StyledIconLink>
                    }
                  >
                    <VStack spacing={{ base: 12, md: 16 }}>
                      <Typography
                        size={4}
                        weight={'medium'}
                        role="heading"
                        aria-level={3}
                      >
                        {service.title}
                      </Typography>
                      <Typography textAlign={'left'}>
                        {service.excerpt}
                      </Typography>
                    </VStack>
                  </StyledTeaserCard>
                </>
              );
            },
          )}
          <Layer
            closeLabel={$t('BACK')}
            open={isOpen}
            onOpenChange={() => {
              openLayer(false);
            }}
            css={{ zIndex: '100' }}
          >
            <VStack spacing={64}>
              <VStack spacing={24}>
                <Headline type="h3">{serviceTitle}</Headline>
                <Accordion
                  type="single"
                  collapsible
                  items={transformItems(onlineBankingServices[idx])}
                  onValueChange={handleAccordionValueChange}
                />
              </VStack>
            </VStack>
          </Layer>
        </SimpleGrid>
      </SandSection>
      <Section>
        <VStack spacing={'$subsection'}>
          <NeuiHeadline
            type="h3"
            renderAs="h2"
            subline={
              'Hier finden Sie Informationen zu allen Themen rund um Produkte, Verwaltung, Online Banking und weitere Bankthemen.'
            }
          >
            Unterstützung & Informationen
          </NeuiHeadline>
          <SimpleGrid
            spacing={{ base: '$space$component-8' }}
            columns={{ base: 1, xs: 1, sm: 2, md: 3 }}
          >
            {topCategories.map((category, index) => {
              const { slug, title } = category;
              const categoryInfo = categoryMap[basename].get(
                slug,
              ) as CategoryInfo;

              const onClick = (
                _e: React.MouseEvent<HTMLElement, MouseEvent>,
              ) => {
                // e?.preventDefault();
                trackButtonClick?.(title, 'category', []);
                GA4TrackTeaserCtaClick(
                  'CTA Picture',
                  title,
                  'Mehr erfahren',
                  makeLink({
                    href: slug,
                    absoluteUrl: true,
                    alwaysPrependBasePath: true,
                  }),
                  'Unterstützung & Informationen',
                );
                // navigate(slug, router);
              };
              return (
                <TeaserCard
                  key={index}
                  href={slug}
                  onClick={onClick}
                  hover
                  bordered
                  picture={
                    <ImageWrapper>
                      <Image
                        src={categoryInfo.icon as string}
                        alt=""
                        loading="lazy"
                      />
                    </ImageWrapper>
                  }
                  footer={
                    <StyledIconLink
                      iconPosition="right"
                      animationDirection="none"
                      renderAs={Typography}
                      icon={interaction_arrows_arrowRight}
                      data-cy={'category'}
                    >
                      Mehr erfahren
                    </StyledIconLink>
                  }
                >
                  <VStack spacing={{ base: 8, md: 16 }}>
                    <Typography
                      size={4}
                      weight={'medium'}
                      aria-level={3}
                      role="heading"
                    >
                      {title}
                    </Typography>
                    <Text textAlign={'left'} type="info">
                      {categoryInfo.description}
                    </Text>
                  </VStack>
                </TeaserCard>
              );
            })}
          </SimpleGrid>
        </VStack>
      </Section>
    </>
  );
};

export const StyledSection = styled(Section, {
  paddingTop: '0 !important',
});

const StyledTeaserCard = styled(TeaserCard, {
  '&:focus-visible': {
    outline: '2px solid $text-standard',
    outlineOffset: 4,
  },
});

const SearchContainer = styled(VStack, {
  position: 'relative',
  width: '100%',
  maxWidth: '792px',
  height: '66px',
  marginBottom: '$layout-2',
});
